import {required} from 'vuelidate/lib/validators'
import { mapGetters, mapMutations, mapActions} from 'vuex';
import icons from "../../../../mixins/icons";

export default {
    props: ['popupType', 'selectedItem'],
    mixins: [icons],
    data() {
        return {
            card_id:null,
            form: {
                user_id: 0,
                name: '',
                description: '',
                user_group_id: 0,
                login: '',
                password: '',
                new_password: '',
                email: '',
                internal_phone_binotel: '',
                created: '',
                departments:[],
                cards:[],
                sms_phone:'',
            },
            changePassword: false,
            cards:[],
            user_group: '',
            user_department: '',
        }
    },

    validations: {
        form: {
            name: {
                required
            },
        }
    },
    computed: {
        ...mapGetters({
            user:'contacts/user',
            usersGroup:'contacts/usersGroup',
            departments: 'catalog/departments',
            cardList: 'rate/cardList',
            profile: 'profile/profile'
        }),
    },
    watch:{
        user(e){
            if(e){
                this.getUserInfoData();
            }
        },
        cardList(){
            this.combineCardNameWitchCode()
        },
        usersGroup(){
            this.getUserGroup();
        },
        departments(){
            this.getUserDepartment();
        }
    },
    created() {
        if(this.user){
            this.getUserInfoData();
        }
        this.getUsersGroup();
        this.getDepartments();
        this.getCardList();
        if(this.cardList){
            this.combineCardNameWitchCode()
        }
        this.getUserGroup();
        this.getUserDepartment();
    },
    methods: {
        getUserGroup(){
            if(this.usersGroup){
                const result = this.usersGroup.data.filter(item => item.id === this.form.user_group_id)[0]
                this.user_group =  result.title ? result.title : ''
            }
        },
        getUserDepartment(){
            this.user_department = '';
            if(this.departments){
                const result = this.departments.data.filter(itemDepartment => this.form.departments.some(itemForm => itemDepartment.id === itemForm.id))
                if(result.length){
                    result.map(itemDepartment => {this.user_department += itemDepartment.title+', '})
                }else{
                    this.user_department =  ''
                }
            }
        },
        getUserInfoData(){
            this.form = {
                user_id: this.user.id,
                name: this.user.name,
                user_group_id: this.user.user_group_id,
                login: this.user.login,
                email: this.user.email,
                description: this.user.description,
                created: this.$moment(this.user.created_at).format('YYYY-MM-DD HH:mm:ss'),
                departments: this.user.departments,
                cards: this.user.cards ? this.user.cards : [] ,
                internal_phone_binotel: this.user.internal_phone_binotel,
                sms_phone: this.user.sms_phone
            }
        },
        combineCardNameWitchCode(){
            this.cardList.models.data.map(item => {
                item.cardInfo = item.card + ' - ' + item.full_name;
            })
            this.cards = this.cardList.models.data;
        },
        closePopup() {
            this.$emit('closePopup')
        },
        addToCardList(){
            const card = this.cardList.models.data.filter(item => item.id === this.card_id)[0]
            this.form.cards.push(card);
            this.card_id = null;
        },
        cardMainChecked(item){
            this.form.cards.map(itemCard=>{
                itemCard.main = 0;
            })
            return item.main = 1;
        },
        removeCard(item){
            const index = this.form.cards.indexOf(item);
            this.form.cards.splice(index, 1);
        },
        sendPriceCategory() {
            if(this.user){
                if(this.form.departments.length && !Number.isInteger(this.form.departments[0])){
                    const departments = [];
                    this.form.departments.map(item=>{departments.push(item.id)});
                    this.form.departments = departments;
                }
                if(this.form.cards.length){
                    const card = this.form.cards;
                    this.form.cards = []
                    card.map(itemCard => {
                        this.form.cards.push({card_id:itemCard.id, main: itemCard.main});
                    })
                }
                this.$emit('changeUserForm', this.form)
            }else{
                this.$emit('sendUser', this.form)
            }
        },
        ...mapActions({
            getUsersGroup:'contacts/getUsersGroup',
            getDepartments: 'catalog/getDepartments',
            getCardList:'rate/getCardList',
        }),
        ...mapMutations({
            changeUser:'contacts/changeUser'
        }),
    },
    destroyed() {
        this.changeUser(null);
    }
}
